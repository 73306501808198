/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */

import { System } from '../icons';

interface NotFoundMessageProps {
  title?: string;
  message?: string;
  outerDivClassName?: string;
  infoIconClassName?: string;
}

export const NotFoundMessage: React.FC<NotFoundMessageProps> = ({
  title,
  message,
  outerDivClassName,
  infoIconClassName,
}) => {
  return (
    <div
      className={`flex w-full flex-col items-center justify-center gap-y-2 text-center ${outerDivClassName || 'my-[200px]'}`}
    >
      {System.InfoIcon(`size-12 text-form-input-disabled ${infoIconClassName}`)}
      <h3 className="text-h3 text-body-primary">{title}</h3>
      <p className="text-body text-form-input-disabled">{message}</p>
    </div>
  );
};
