/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */
import { format, parseISO } from 'date-fns';

export const formatDateForDateRangeDisplay = (date: Date, locale: any) => {
  const day = String(date.getDate()).padStart(2, '0');
  const month = date.toLocaleString(locale, { month: 'short' });
  const year = date.getFullYear();
  return `${day} ${month} ${year}`;
};

export const formatDateMonthTime = (dateString: string) => {
  if (dateString) {
    const date = new Date(dateString);
    return format(date, 'd MMM h:mma');
  } else {
    return;
  }
};

export const formatDateForAppCards = () => {
  const date = Date.now();
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
    timeZone: 'UTC',
    timeZoneName: 'short',
  };

  const formatter = new Intl.DateTimeFormat('en-US', options);
  const formattedDate = formatter.format(date);
  return formattedDate;
};

export const formatToLocalISOString = (date: Date): string => {
  const offset = date.getTimezoneOffset();
  const adjustedDate = new Date(date.getTime() - offset * 60000);
  return adjustedDate.toISOString();
};

export const formatApiDate = (apiDate?: string): string =>
  apiDate ? format(parseISO(apiDate), 'dd MMM yyyy hh:mm a') : '';
